import React from 'react';

import { Hyperlink } from '@motorway/mw-highway-code/alpha';

import styles from './RenderTextWithActionLink.scss';

type Props = {
  onClick: () => void;
  value: React.ReactNode;
  actionText: React.ReactNode;
}

export const RenderTextWithActionLink = ({ actionText, onClick, value }: Props) => (
  <div className={styles.container}>
    <span className={styles.value}>{value}</span>
    <p className={styles.noWrap}>
      <Hyperlink as='button' label={actionText} onClick={onClick} />
    </p>
  </div>
);

export default RenderTextWithActionLink;
