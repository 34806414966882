import React from 'react';
import { useFormState } from 'react-final-form';

import { MDText } from 'i18n-react';

import { Button } from '@motorway/mw-highway-code';
import { Variant } from '@motorway/mw-highway-code/src/types';

import LocalTexts from '../../AuthenticationTexts.json';

const LocalT = new MDText(LocalTexts);

const BUTTON_LABEL = {
  EMAIL_CHECK: LocalT.translate('components.formButton.emailCheck'),
  PASSWORD_RESET: LocalT.translate('components.formButton.passwordReset'),
  PASSWORD_RESET_EMAIL: LocalT.translate('components.formButton.passwordResetEmail'),
  SIGN_IN: LocalT.translate('components.formButton.signIn'),
  SIGN_UP: LocalT.translate('components.formButton.signUp'),
} as const;

type FormButtonLabel = typeof BUTTON_LABEL;

type FormButtonProps = {
  readonly type: keyof FormButtonLabel;
  readonly showIcon?: boolean
  readonly variant?: Variant;
}

const FormButton = ({ showIcon = true, type, variant }: FormButtonProps) => {
  const { submitting } = useFormState();

  return (
    <Button
      fullWidth
      icon={showIcon ? 'chevron' : undefined}
      label={BUTTON_LABEL[type]}
      loading={ submitting }
      type='submit'
      variant={variant}
    />
  );
};

export default FormButton;
