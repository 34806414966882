import React from 'react';

import VehicleImage from '../../../../../../../components/misc/VehicleImage/VehicleImage';

import styles from './Background.scss';

interface CarsBackgroundProps {
  children: React.ReactNode;
}

const Background = ({ children }: CarsBackgroundProps) => (
  <div className={styles.container}>
    <div className={styles.cars}>
      <VehicleImage className={styles.carLeft} name="silver" />
      <VehicleImage className={styles.carRight} name="black" />
    </div>
    {children}
  </div>
);

export default Background;
