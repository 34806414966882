import { useCallback } from 'react';

import { ToastStatuses, useToast } from '@motorway/motorway-storybook-cra';

import styles from '../../customerHub/Pages/HubResult/Toast.scss';

type ErrorToastType = {
  title: string;
  message?: string;
}

const ToastContent = ({ message, title }: ErrorToastType) =>
  <>
    <h2>{title}</h2>
    {message && <p> {message} </p>}
  </>;

const useToastNotification = () => {
  const { add } = useToast();

  const showErrorToast = useCallback(({ message, title }: ErrorToastType) => {
    add(
      <ToastContent {...{ message, title }} />,
      ToastStatuses.ERROR,
      { className: styles.toast, longMessage: true },
    );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const showSuccessToast = useCallback(({ message, title }: ErrorToastType) => {
    add(
      <ToastContent {...{ message, title }} />,
      ToastStatuses.SUCCESS,
      { className: styles.toast, longMessage: true },
    );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    showErrorNotification: showErrorToast,
    showSuccessNotification: showSuccessToast,
  };
};

export default useToastNotification;
