import React from 'react';
import cx, { Argument as ClassNameType } from 'classnames';

import styles from './PageHeader.scss';

export type PageHeaderProps = {
  readonly className?: ClassNameType;
  readonly strap?: string;
  readonly title: string;
}

const PageHeader = ({
  className,
  strap,
  title,
}: PageHeaderProps) => (
  <div className={cx(styles.component, className)}>
    <div className={styles.content}>
      <h1 className={cx(styles.title, { [styles.noStrap]: !strap })}>{title}</h1>
      { strap && <p>{strap}</p> }
    </div>
  </div>
);

export default PageHeader;
