import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { classNamePropType } from 'Utilities/propTypes';

import blackImgAVIF from '../../../../client/assets_seller/vehicleImages/car-black.avif';
import blackImgPNG from '../../../../client/assets_seller/vehicleImages/car-black.png';
import blackImgWEBP from '../../../../client/assets_seller/vehicleImages/car-black.webp';
import blackBackdropImgAVIF from '../../../../client/assets_seller/vehicleImages/car-black-backdrop.avif';
import blackBackdropImgPNG from '../../../../client/assets_seller/vehicleImages/car-black-backdrop.png';
import blackBackdropImgWEBP from '../../../../client/assets_seller/vehicleImages/car-black-backdrop.webp';
import blackBackdropImgSmallAVIF from '../../../../client/assets_seller/vehicleImages/car-black-backdrop-small.avif';
import blackBackdropImgSmallPNG from '../../../../client/assets_seller/vehicleImages/car-black-backdrop-small.png';
import blackBackdropImgSmallWEBP from '../../../../client/assets_seller/vehicleImages/car-black-backdrop-small.webp';
import blackImgSmallAVIF from '../../../../client/assets_seller/vehicleImages/car-black-small.avif';
import blackImgSmallPNG from '../../../../client/assets_seller/vehicleImages/car-black-small.png';
import blackImgSmallWEBP from '../../../../client/assets_seller/vehicleImages/car-black-small.webp';
import blueImgAVIF from '../../../../client/assets_seller/vehicleImages/car-blue.avif';
import blueImgPNG from '../../../../client/assets_seller/vehicleImages/car-blue.png';
import blueImgWEBP from '../../../../client/assets_seller/vehicleImages/car-blue.webp';
import blueImgSmallAVIF from '../../../../client/assets_seller/vehicleImages/car-blue-small.avif';
import blueImgSmallPNG from '../../../../client/assets_seller/vehicleImages/car-blue-small.png';
import blueImgSmallWEBP from '../../../../client/assets_seller/vehicleImages/car-blue-small.webp';
import greyImgAVIF from '../../../../client/assets_seller/vehicleImages/car-grey.avif';
import greyImgPNG from '../../../../client/assets_seller/vehicleImages/car-grey.png';
import greyImgWEBP from '../../../../client/assets_seller/vehicleImages/car-grey.webp';
import greyImgSmallAVIF from '../../../../client/assets_seller/vehicleImages/car-grey-small.avif';
import greyImgSmallPNG from '../../../../client/assets_seller/vehicleImages/car-grey-small.png';
import greyImgSmallWEBP from '../../../../client/assets_seller/vehicleImages/car-grey-small.webp';
import greySuvImgAVIF from '../../../../client/assets_seller/vehicleImages/car-grey-suv.avif';
import greySuvImgPNG from '../../../../client/assets_seller/vehicleImages/car-grey-suv.png';
import greySuvImgWEBP from '../../../../client/assets_seller/vehicleImages/car-grey-suv.webp';
import greySuvImgSmallAVIF from '../../../../client/assets_seller/vehicleImages/car-grey-suv-small.avif';
import greySuvImgSmallPNG from '../../../../client/assets_seller/vehicleImages/car-grey-suv-small.png';
import greySuvImgSmallWEBP from '../../../../client/assets_seller/vehicleImages/car-grey-suv-small.webp';
import silverImgAVIF from '../../../../client/assets_seller/vehicleImages/car-silver.avif';
import silverImgPNG from '../../../../client/assets_seller/vehicleImages/car-silver.png';
import silverImgWEBP from '../../../../client/assets_seller/vehicleImages/car-silver.webp';
import silverBackdropImgAVIF from '../../../../client/assets_seller/vehicleImages/car-silver-backdrop.avif';
import silverBackdropImgPNG from '../../../../client/assets_seller/vehicleImages/car-silver-backdrop.png';
import silverBackdropImgWEBP from '../../../../client/assets_seller/vehicleImages/car-silver-backdrop.webp';
import silverBackdropImgSmallAVIF from '../../../../client/assets_seller/vehicleImages/car-silver-backdrop-small.avif';
import silverBackdropImgSmallPNG from '../../../../client/assets_seller/vehicleImages/car-silver-backdrop-small.png';
import silverBackdropImgSmallWEBP from '../../../../client/assets_seller/vehicleImages/car-silver-backdrop-small.webp';
import silverImgSmallAVIF from '../../../../client/assets_seller/vehicleImages/car-silver-small.avif';
import silverImgSmallPNG from '../../../../client/assets_seller/vehicleImages/car-silver-small.png';
import silverImgSmallWEBP from '../../../../client/assets_seller/vehicleImages/car-silver-small.webp';
import cvtHeroAVIF from '../../../../client/assets_seller/vehicleImages/cvt-hero.avif';
import cvtHeroPNG from '../../../../client/assets_seller/vehicleImages/cvt-hero.png';
import cvtHeroWEBP from '../../../../client/assets_seller/vehicleImages/cvt-hero.webp';
import heroBMWBlueAVIF from '../../../../client/assets_seller/vehicleImages/hero-bmw-blue.avif';
import heroBMWBluePNG from '../../../../client/assets_seller/vehicleImages/hero-bmw-blue.png';
import heroBMWBlueWEBP from '../../../../client/assets_seller/vehicleImages/hero-bmw-blue.webp';
import heroBMWBlueSmallAVIF from '../../../../client/assets_seller/vehicleImages/hero-bmw-blue-small.avif';
import heroBMWBlueSmallPNG from '../../../../client/assets_seller/vehicleImages/hero-bmw-blue-small.png';
import heroBMWBlueSmallWEBP from '../../../../client/assets_seller/vehicleImages/hero-bmw-blue-small.webp';
import nextHomepageBlackAVIF from '../../../../client/assets_seller/vehicleImages/next_homepage_black.avif';
import nextHomepageBlackPNG from '../../../../client/assets_seller/vehicleImages/next_homepage_black.png';
import nextHomepageBlackWEBP from '../../../../client/assets_seller/vehicleImages/next_homepage_black.webp';
import nextHomepageWhiteAVIF from '../../../../client/assets_seller/vehicleImages/next_homepage_white.avif';
import nextHomepageWhitePNG from '../../../../client/assets_seller/vehicleImages/next_homepage_white.png';
import nextHomepageWhiteWEBP from '../../../../client/assets_seller/vehicleImages/next_homepage_white.webp';

import breakpoints from '../Breakpoints/Breakpoints.scss';
import styles from './VehicleImage.scss';

const namesArray = ['black', 'blue', 'grey', 'silver', 'greysuv', 'silverbackdrop', 'blackbackdrop', 'next_homepage_black', 'next_homepage_white', 'hero_bmw_blue', 'cvt_hero'];

const CAR_NAMES = namesArray.reduce((acc, val) => {
  acc[val.toUpperCase()] = val;
  return acc;
}, {});

const getCarImage = (c) => {
  switch (c) {
    case (CAR_NAMES.BLUE):
      return {
        avif: blueImgAVIF,
        avifSmall: blueImgSmallAVIF,
        height: 448,
        png: blueImgPNG,
        pngSmall: blueImgSmallPNG,
        webp: blueImgWEBP,
        webpSmall: blueImgSmallWEBP,
        width: 896,
      };

    case (CAR_NAMES.GREY):
      return {
        avif: greyImgAVIF,
        avifSmall: greyImgSmallAVIF,
        height: 448,
        png: greyImgPNG,
        pngSmall: greyImgSmallPNG,
        webp: greyImgWEBP,
        webpSmall: greyImgSmallWEBP,
        width: 896,
      };

    case (CAR_NAMES.SILVER):
      return {
        avif: silverImgAVIF,
        avifSmall: silverImgSmallAVIF,
        height: 448,
        png: silverImgPNG,
        pngSmall: silverImgSmallPNG,
        webp: silverImgWEBP,
        webpSmall: silverImgSmallWEBP,
        width: 896,
      };
    case (CAR_NAMES.SILVERBACKDROP):
      return {
        avif: silverBackdropImgAVIF,
        avifSmall: silverBackdropImgSmallAVIF,
        height: 448,
        png: silverBackdropImgPNG,
        pngSmall: silverBackdropImgSmallPNG,
        webp: silverBackdropImgWEBP,
        webpSmall: silverBackdropImgSmallWEBP,
        width: 896,
      };

    case (CAR_NAMES.GREYSUV):
      return {
        avif: greySuvImgAVIF,
        avifSmall: greySuvImgSmallAVIF,
        height: 448,
        png: greySuvImgPNG,
        pngSmall: greySuvImgSmallPNG,
        webp: greySuvImgWEBP,
        webpSmall: greySuvImgSmallWEBP,
        width: 896,
      };

    case (CAR_NAMES.BLACKBACKDROP):
      return {
        avif: blackBackdropImgAVIF,
        avifSmall: blackBackdropImgSmallAVIF,
        height: 372,
        png: blackBackdropImgPNG,
        pngSmall: blackBackdropImgSmallPNG,
        webp: blackBackdropImgWEBP,
        webpSmall: blackBackdropImgSmallWEBP,
        width: 746,
      };

    case (CAR_NAMES.NEXT_HOMEPAGE_BLACK):
      return {
        avif: nextHomepageBlackAVIF,
        avifSmall: nextHomepageBlackAVIF,
        height: 248,
        png: nextHomepageBlackPNG,
        pngSmall: nextHomepageBlackPNG,
        webp: nextHomepageBlackWEBP,
        webpSmall: nextHomepageBlackWEBP,
        width: 469,
      };

    case (CAR_NAMES.NEXT_HOMEPAGE_WHITE):
      return {
        avif: nextHomepageWhiteAVIF,
        avifSmall: nextHomepageWhiteAVIF,
        height: 280,
        png: nextHomepageWhitePNG,
        pngSmall: nextHomepageWhitePNG,
        webp: nextHomepageWhiteWEBP,
        webpSmall: nextHomepageWhiteWEBP,
        width: 473,
      };

    case (CAR_NAMES.HERO_BMW_BLUE):
      return {
        avif: heroBMWBlueAVIF,
        avifSmall: heroBMWBlueSmallAVIF,
        height: 955,
        png: heroBMWBluePNG,
        pngSmall: heroBMWBlueSmallPNG,
        webp: heroBMWBlueWEBP,
        webpSmall: heroBMWBlueSmallWEBP,
        width: 1549,
      };

    case (CAR_NAMES.CVT_HERO):
      return {
        avif: cvtHeroAVIF,
        avifSmall: cvtHeroAVIF,
        height: 714,
        png: cvtHeroPNG,
        pngSmall: cvtHeroPNG,
        webp: cvtHeroWEBP,
        webpSmall: cvtHeroWEBP,
        width: 1681,
      };
    default:
      return {
        avif: blackImgAVIF,
        avifSmall: blackImgSmallAVIF,
        height: 372,
        png: blackImgPNG,
        pngSmall: blackImgSmallPNG,
        webp: blackImgWEBP,
        webpSmall: blackImgSmallWEBP,
        width: 746,
      };
  }
};

const tabletMobileBreakpoint = breakpoints.breakpointTabletMobile; // 744px
const smallImgWidth = 448;

const VehicleImage = ({
  className,
  imgProps = {},
  name = CAR_NAMES.BLACK,
  vehicleProps = {},
}) => {
  const {
    avif,
    avifSmall,
    height,
    png,
    pngSmall,
    webp,
    webpSmall,
    width,
  } = getCarImage(name);

  return (
    <div {...vehicleProps} className={cx(styles.vehicle, className)}>
      <picture>
        <source height={height / 2} media={`(max-width: ${tabletMobileBreakpoint})`} srcSet={avifSmall} type="image/avif" width={width / 2} />
        <source srcSet={avif} type="image/avif" />
        <source height={height / 2} media={`(max-width: ${tabletMobileBreakpoint})`} srcSet={webpSmall} type="image/webp" width={width / 2} />
        <source srcSet={webp} type="image/webp" />
        {/* fallback for Safari 13 */}
        <img
          alt={`Aerial image of a ${name} car`}
          decoding='async'
          fetchpriority='high'
          sizes={`(max-width: ${tabletMobileBreakpoint}) ${smallImgWidth}px, ${width}px`}
          src={png}
          srcSet={`${pngSmall} ${smallImgWidth}w, ${png} ${width}w`}
          {...{ height, width }}
          {...imgProps}
        />
      </picture>
    </div>
  );
};

VehicleImage.propTypes = {
  className: classNamePropType,
  imgProps: PropTypes.shape({
    className: classNamePropType,
  }),
  name: PropTypes.oneOf(namesArray),
  vehicleProps: PropTypes.shape({
    onClick: PropTypes.func,
  }),
};

export default VehicleImage;
