import React, { useContext, useState } from 'react';
import { Field, useForm, useFormState } from 'react-final-form';
import { withRouter } from 'react-router';
import cx from 'classnames';

import { MDText } from 'i18n-react';

import { FeedbackTooltip } from '@motorway/motorway-storybook-cra';
import { Input } from '@THC/components/Input';

import cypressIds from 'CypressId';

import { UserContext } from 'Context/user';
import { VehicleLookupContext } from 'Context/vehicleLookup';

import { composeValidators, emailValidator, getFieldStateWithSubmissionErrors, platformEmailValidator, requiredValidator } from 'Utilities/formValidators';
import { applyCypressData } from 'Utilities/index';

import LocalTexts from '../../AuthenticationTexts.json';

import RenderTextWithActionLink from './SubComponents/RenderTextWithActionLink/RenderTextWithActionLink';

import styles from './EmailField.scss';

const LocalT = new MDText(LocalTexts);

export type EmailFieldProps = {
  initial?: string;
  isUniquenessChecked?: boolean;
  isEmailEditable?: boolean;
  isNewUserFromSignInRoute?: boolean;
  showSignInLink?: boolean;
  onReset?: () => void;
};

const texts = {
  emailAlreadyExists: LocalT.translate('components.emailField.errorMessage.alreadyExists'),
  emailValidation: LocalT.translate('components.emailField.errorMessage.default'),
  label: LocalT.translate('components.emailField.label'),
  notYou: LocalT.translate('components.emailField.notYou'),
  placeholder: LocalT.translate('components.emailField.placeholder'),
  requiredValidation: LocalT.translate('components.emailField.errorMessage.default'),
  tooltipSignInLink: LocalT.translate('components.emailField.errorMessage.tooltipSignInLink'),
  tooltipSignInNoAccount: LocalT.translate('signIn.toastErrors.notFound.title'),
} as Record<string, string>;

// TODO: Refactor AlreadyExistsToolTip and place in helper file
// Sign in link should not be displayed on the sign-in page.
const AlreadyExistsToolTip = withRouter(({ history, showSignInLink = false }: any) => {
  const { vehicleLookupActions, vehicleLookupState: vehicle } = useContext(VehicleLookupContext);
  const signInHandler = () => {
    if (vehicle?.marketplaceEligible?.eligible) {
      vehicleLookupActions.update({ owned: true });
    } else {
      history.push('/sign-in');
    }
  };

  return (
    <>
      <span>{texts.emailAlreadyExists}</span>
      {showSignInLink && (
        <>
          {' '}
          <span
            className="mw-link cursor-pointered"
            onClick={signInHandler}
            role="button"
            tabIndex={-1}
          >
            {texts.tooltipSignInLink}
          </span>
          .
        </>
      )}
    </>
  );
});

const FIELD_NAME = 'email';

const EmailField = ({
  initial,
  isEmailEditable = true,
  isNewUserFromSignInRoute = false,
  isUniquenessChecked = false,
  onReset,
  showSignInLink = true,
}: EmailFieldProps) => {
  const { userState: user } = useContext(UserContext);
  const [isNewUser, setIsNewUser] = useState(false);
  const { dirtySinceLastSubmit, values } = useFormState();
  const { reset } = useForm();

  const newUserValidator = () => () => {
    if (dirtySinceLastSubmit) {
      setIsNewUser(false);
      return undefined;
    }
    setIsNewUser(true);
    return texts.tooltipSignInNoAccount;
  };

  const validators = [
    requiredValidator(texts.requiredValidation),
    emailValidator(texts.emailValidation),
    isUniquenessChecked ? platformEmailValidator(
      texts.requiredValidation,
      <AlreadyExistsToolTip {...{ showSignInLink }} />,
      user,
    ) : () => undefined,
    isNewUserFromSignInRoute ? newUserValidator() : () => undefined,
  ];

  const onWrongEmail = () => {
    reset();
    onReset?.();
    setIsNewUser(false);
  };

  if (!isEmailEditable && values?.[FIELD_NAME]) {
    return (<RenderTextWithActionLink
      actionText = { texts.notYou }
      onClick={onWrongEmail}
      value={values[FIELD_NAME]}
    />);
  }

  return (
    <Field
      id={FIELD_NAME}
      initialValue={initial}
      name={FIELD_NAME}
      type='email'
      validate={composeValidators(...validators)}
    >
      {({ input, meta, ...props }) => (
        <FeedbackTooltip
          className={cx(styles.tooltip, { [styles.hidden]: isNewUser && !dirtySinceLastSubmit })}
          content={meta.error || meta.submitError}
          intent={getFieldStateWithSubmissionErrors(meta)}
        >
          <Input
            formProps={{
              ...props,
              input: {
                ...input,
                ...applyCypressData(cypressIds.fields.emailField),
                autoComplete: FIELD_NAME,
                autoFocus: true,
                placeholder: texts.placeholder,
              },
              meta: {
                ...meta,
                active: undefined,
              },
            }}
            fullWidth={true}
            id={ FIELD_NAME }
            intent={getFieldStateWithSubmissionErrors(meta)}
            label={texts.label}
            showLabel={true}
            size='medium'
          />
        </FeedbackTooltip>
      )}
    </Field>
  );
};

export default EmailField;
