import React from 'react';

import PageHeader, { PageHeaderProps } from '../../../common/PageHeader/PageHeader';

import Background from './SubComponents/Background/Background';

import styles from './PageLayout.scss';

type PageLayoutProps = {
  children: React.ReactNode;
  isEmbedded: boolean;
  pageTitle?: PageHeaderProps;
};

const PageLayout = ({ children, isEmbedded, pageTitle }: PageLayoutProps) => (
  <>
    {(!isEmbedded && pageTitle) && <PageHeader {...pageTitle} className={styles.removeZIndex}/>}
    <Background>
      {children}
    </Background>
  </>
);

export default PageLayout;
