import React from 'react';
import { Form } from 'react-final-form';
import { FORM_ERROR } from 'final-form';

export type SignUpFormValues = {
  readonly consent: 'true' | 'false';
  readonly email: string;
  readonly name: string;
  readonly password: string;
  readonly phone: string;
}

export type SignInFormValues = {
  readonly email: string;
  readonly password: string;
}
export type PasswordResetFormValues = {
  readonly email: string;
  readonly password: string;
  readonly verificationCode: string;
}
export type UpdatePasswordFormValues = {
  readonly password: string;
  readonly newPassword: string;
}

export type ForgotPasswordFormValue = {
  readonly email: string
}

type AuthFormValues = SignUpFormValues | SignInFormValues | ForgotPasswordFormValue | UpdatePasswordFormValues;

export type AuthFormErrors<T extends AuthFormValues> = {
  [K in keyof T | typeof FORM_ERROR]?: React.ReactNode;
};

type AuthenticationFormProps<T extends AuthFormValues> = {
  readonly children: React.ReactNode;
  readonly onSubmit: (values: T) => Promise<AuthFormErrors<T>|void>;
  readonly initialValues?: Partial<T>;
  readonly validateOnBlur?: boolean;
};

const AuthenticationForm = <T extends AuthFormValues>(
  { children, initialValues, onSubmit, validateOnBlur = true }: AuthenticationFormProps<T>,
) => (
  <Form
    {...initialValues}
    keepDirtyOnReinitialize
    onSubmit={(values: T) => onSubmit(values)}
    render={({ handleSubmit }) => (
      <form
        noValidate
        onSubmit={handleSubmit}>
        { children}
      </form>
    )}
    validateOnBlur={validateOnBlur}
  />
);
export default AuthenticationForm;
